import {useCallback, useEffect, useState} from "react";
import {Provider, useDispatch, useSelector} from "react-redux";
import {createStore} from "@reduxjs/toolkit";
import Graph from "react-graph-vis";

const getInitialEnvParams = () => {
  const fromLocalStorage = localStorage.getItem('environment')
  if (fromLocalStorage) return JSON.parse(fromLocalStorage)
  return {
    'min': 0,
    'max': 0,
    'mount': 1,
    'segment_len': 10
  }
}

const initialState = {
  'environment': getInitialEnvParams(),
  'equipment_blocks': [0],
  'equipment': {
    0: {}
  },
  'circuit': {}
}

const SET_ENV = 'set_environment'
const ADD_EQUIPMENT = 'add_equipment'
const REMOVE_EQUIPMENT = 'remove_equipment'
const SELECT_EQUIPMENT = 'select_equipment'
const UPDATE_STRINGS = 'update_strings'
const SET_CIRCUIT = 'set_circuit'
const REMOVE_OPTIMIZER = 'remove_optimizer'


const API_ENDPOINT = "https://d4pv-core.dev.angleto.com:8001"

const store = createStore(
  (state = initialState, action) => {
    if (action.type === SET_ENV) {
      let env = state.environment
      if (action.payload.param === 'mount' || action.payload.param === 'segment_len') {
        env[action.payload.param] = parseInt(action.payload.value)
      } else {
        env[action.payload.param] = parseFloat(action.payload.value)
      }
      localStorage.setItem('environment', JSON.stringify(env))
      return {
        ...state,
        environment: { ...env}
      }
    }
    if (action.type === ADD_EQUIPMENT) {
      const lastBlock = state.equipment_blocks.slice(-1).pop()
      return {
        ...state,
        equipment_blocks: state.equipment_blocks.concat([lastBlock + 1])
      }
    }
    if (action.type === REMOVE_EQUIPMENT) {
      const filtered = state.equipment_blocks.filter((it) => it !== action.payload.eq_id)
      const equipment = state.equipment
      if (equipment[action.payload.eq_id] !== undefined) {
        delete equipment[action.payload.eq_id]
      }
      return {
        ...state,
        equipment_blocks: filtered,
        equipment: { ...equipment}
      }
    }
    if (action.type === SELECT_EQUIPMENT) {
      const equipment = state.equipment
      if (equipment[action.payload.eq_id] === undefined) {
        equipment[action.payload.eq_id] = {}
      }
      equipment[action.payload.eq_id][action.payload.eq] = action.payload.obj
      return {
        ...state,
        equipment: { ...equipment}
      }
    }
    if (action.type === UPDATE_STRINGS) {
      const equipment = state.equipment
      const currentEquipment = state.equipment[action.payload.eq_id]
      if (currentEquipment.strings === undefined) {
        currentEquipment.strings = {}
      }
      currentEquipment.strings[action.payload.str_id] = parseInt(action.payload.length)
      equipment[action.payload.eq_id] = currentEquipment
      return {
        ...state,
        equipment: { ...equipment}
      }
    }
    if (action.type === SET_CIRCUIT) {
      return {
        ...state,
        circuit: {...action.payload.circuit}
      }
    }
    if (action.type === REMOVE_OPTIMIZER) {
      const equipment = state.equipment
      if (equipment[action.payload.eq_id] !== undefined && equipment[action.payload.eq_id]['optimizer'] !== undefined) {
        delete equipment[action.payload.eq_id]['optimizer']
      }
      return {
        ...state,
        equipment: {...equipment}
      }
    }
    return state
  }
)

function EnvironmentParameters() {
  const [mounts, setMounts] = useState({})
  const [min, setMin] = useState(initialState.environment.min)
  const [max, setMax] = useState(initialState.environment.max)
  const [mountType, setMountType] = useState(initialState.environment.mount)
  const [segmentLen, setSegmentLen] = useState(initialState.environment.segment_len)

  useEffect(() => {
    const url = API_ENDPOINT + '/mount_types'
    fetch(url).then((res) => {
      res.json().then((mounts) => {
        setMounts(mounts.mounts)
      })
    })
  }, [])
  const dispatch = useDispatch()

  const setEnvironment = (field, element)  => {
    if (field === 'min') setMin(element.value)
    if (field === 'max') setMax(element.value)
    if (field === 'mount') setMountType(element.value)
    if (field === 'segment_len') setSegmentLen(element.value)
    dispatch({
      type: SET_ENV,
      payload: {
        param: field,
        value: element.value
      }
    })
  }

  return (
    <div className="card">
      <div className="card-header">Environment</div>
      <div className="card-body">
        <form>
          <div className="form-group">
            <label htmlFor="t_min">Min. temperature</label>
            <input type="number" className="form-control" id="t_min" value={min} placeholder="0" onChange={(e) => setEnvironment('min', e.target)}/>
          </div>
          <div className="form-group">
            <label htmlFor="t_max">Max. temperature</label>
            <input type="number" className="form-control" id="t_max" value={max} placeholder="0" onChange={(e) => setEnvironment('max', e.target)}/>
          </div>
          <div className="form-group">
            <label htmlFor="mount">Mount type</label>
            <select id="mount" name="mount" className="form-control" value={mountType} onChange={(e) => setEnvironment('mount', e.target)}>
              {
                Object.entries(mounts).map(([title, value]) => {
                  return (<option key={value} value={value}>{title}</option>)
                })
              }
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="segment_len">Wire segment len</label>
            <input type="number" className="form-control" id="segment_len" value={segmentLen} placeholder="0" onChange={(e) => setEnvironment('segment_len', e.target)}/>
          </div>
        </form>
      </div>
    </div>
  )
}

function DCOptimizersSelect(props) {
  const [optimizers, setOptimizers] = useState([])
  const inverter = useSelector(state => state.equipment[props.eq_id].inverter)
  const environment = useSelector(state => state.environment)
  useEffect(() => {
    const url = API_ENDPOINT + '/dc_optimizers'
    fetch(url).then(res => {
      res.json().then(optimizers => {
        optimizers.unshift({
          'title': 'Select the DC optimizer'
        })
        setOptimizers(optimizers)
      })
    })
  }, [])
  const dispatch = useDispatch()
  const setOptimizer = (e) => {
    const optimizer = optimizers.filter(inv => inv.title === e.target.value)[0]
    dispatch({
      type: SELECT_EQUIPMENT,
      payload: {
        eq_id: props.eq_id,
        eq: 'optimizer',
        obj: optimizer
      }
    })
    fetchSolarPanels(inverter.title, optimizer.title, environment, props.solarPanelSetFn)
    props.stringRenderFn()
  }

  return (
    <div className="form-group">
      <label htmlFor="dc_optimizer">DC Optimizer</label>
      <select id="dc_optimizer" name="dc_optimizer" className="form-control" onChange={setOptimizer}>
        {optimizers.map((o, i) => (<option key={i} value={o.title}>{o.title}</option>))}
      </select>
    </div>
  )
}

function SolarPanelsStrings(props) {
  const {stringMinLength, stringMaxLength, stringsMaxCount} = props
  const dispatch = useDispatch()
  const updateEquipmentStrings = (e, str_id) => {
    dispatch({
      type: UPDATE_STRINGS,
      payload: {
        eq_id: props.eq_id,
        str_id: str_id,
        length: e.target.value
      }
    })
  }
  return (<div>
    {Array(stringsMaxCount).fill(0).map((_, i) => (
      <div className="form-group" key={i}>
        <label htmlFor="string" >String {i + 1}</label>
        <input type="number" className="form-control" onChange={(e) => updateEquipmentStrings(e, i)} max={stringMaxLength} min={stringMinLength} />
      </div>)
  )}
  </div>)
}

function fetchSolarPanels(inverter, optimizer, environment, setSolarPanels) {
  let url = API_ENDPOINT + '/panels?inverter=' + inverter + '&t_min=' + environment.min + '&t_max=' + environment.max + '&mount=' + environment.mount
  if (optimizer) {
    url += '&optimizer_id=' + optimizer
  }
  fetch(url).then((res) => {
    res.json().then(panels => {
      panels.unshift({
        'title': 'Select the solar panel',
        'suitable': true
      })
      setSolarPanels(panels)
    })
  })
}

function EquipmentParameters(props) {
  const [inverters, setInverters] = useState([])
  const [solarPanels, setSolarPanels] = useState([{'title': 'Select the inverter first'}])
  const [showOptimizers, needToShowOptimizers] = useState(false)
  const [stringsMaxCount, setStringsMaxCount] = useState(0)
  const [stringMaxLength, setStringMaxLength] = useState(0)
  const [stringMinLength, setStringMinLength] = useState(0)
  const environment = useSelector(state => state.environment)
  const equipment = useSelector(state => state.equipment[props.eq_id])
  const dispatch = useDispatch()

  const checkAndRenderStrings = () => {
    if (equipment === undefined)
      return false
    if (equipment.inverter === undefined || equipment.solar_panel === undefined) {
      return false
    }
    if (equipment.inverter.type === 2 && equipment.optimizer === undefined) {
      return false
    }
    let url = API_ENDPOINT + '/inverters/' + equipment.inverter.id + '/panel/' + equipment.solar_panel.id +
      '?t_min=' + environment.min + '&t_max='  + environment.max + '&mount=' + environment.mount
    if (equipment.optimizer) {
      url += '&optimizer_id=' + equipment.optimizer.title
    }

    fetch(url).then(res => {
      res.json().then(data => {
        setStringsMaxCount(data.max_string_count > 10 ? 10 : data.max_string_count)
        setStringMinLength(data.min_string_length)
        setStringMaxLength(data.max_string_length)
      })
    })
  }
  const checkAndRenderStringCb = useCallback(
    checkAndRenderStrings,
    [environment.max, environment.min, environment.mount, equipment]
  )
  useEffect(() => {
    const url = API_ENDPOINT + '/inverters'
    fetch(url).then((resp) => {
      resp.json().then((inverters) => {
        console.log(inverters)
        inverters.unshift({
          'title': 'Select the inverter'
        })
        setInverters(inverters)
      })
    })
    checkAndRenderStringCb()
  }, [checkAndRenderStringCb, environment, equipment])

  const selectInverter = (e) => {
    const inverter = e.target.value
    const inverter_obj = inverters.filter(inv => inv.id === inverter)[0]
    if (inverter_obj.type === 2) {
      needToShowOptimizers(true)
    } else {
      needToShowOptimizers(false)
      dispatch({
      type: REMOVE_OPTIMIZER,
      payload: {
        eq_id: props.eq_id,
      }
    })
      fetchSolarPanels(inverter, null, environment, setSolarPanels);
    }
    dispatch({
      type: SELECT_EQUIPMENT,
      payload: {
        eq_id: props.eq_id,
        eq: 'inverter',
        obj: inverter_obj
      }
    })
    checkAndRenderStrings()
  }
  const setSolarPanel = (e) => {
    const panel = solarPanels.filter(inv => inv.title === e.target.value)[0]
    dispatch({
      type: SELECT_EQUIPMENT,
      payload: {
        eq_id: props.eq_id,
        eq: 'solar_panel',
        obj: panel
      }
    })
    checkAndRenderStrings()
  }
  const selectWTE = (e) => {
    dispatch({
      type: SELECT_EQUIPMENT,
      payload: {
        eq_id: props.eq_id,
        eq: 'wte',
        obj: e.target.value
      }
    })
  }
  const addEquipmentBlock = (e) =>  {
    dispatch({
      type: ADD_EQUIPMENT,
      payload: {}
    })
  }
  const removeEquipmentBlock = (e) =>  {
    dispatch({
      type: REMOVE_EQUIPMENT,
      payload: {
        eq_id: props.eq_id
      }
    })
  }

  const add_button = (<button className="btn btn-sm btn-primary float-right" onClick={addEquipmentBlock}>+</button>)
  let button = (<button className="btn btn-sm btn-danger float-right" onClick={removeEquipmentBlock}>-</button>)
  if (parseInt(props.eq_id) === 0) {
    button = add_button
  }
  return (
    <div className="card">
      <div className="card-header">Equipment {button} </div>
      <div className="card-body">
        <form>
          <div className="form-group">
            <label htmlFor="inverter" >Inverter</label>
            <select id="mount" name="inverter" className="form-control" onChange={selectInverter}>
              {inverters.map((inverter, i) => (<option key={i} value={inverter.id}>{inverter.title}</option>))}
            </select>
          </div>
          {showOptimizers && <DCOptimizersSelect eq_id={props.eq_id} stringRenderFn={checkAndRenderStrings} solarPanelSetFn={setSolarPanels}/>}
          <div className="form-group">
            <label htmlFor="panel">SolarPanels</label>
            <select id="panel" name="panel" className="form-control" onChange={setSolarPanel}>
              {solarPanels.map((sp, i) => {
                const suitMessage = sp.suitable ? '' : ' (this panel does not suit setup)'
                return (<option key={i} value={sp.title}>{sp.title} {suitMessage}</option>)
              })}
            </select>
          </div>
          {stringsMaxCount > 0 && (
            <div>
              <div className="form-check mb-3">
                <input name="combine" id="combine" className="form-check-input" onChange={selectWTE} value="junction" type="radio"/>
                <label htmlFor="combine" className="form-check-label">Junction box</label>
              </div>
              <div className="form-check mb-3">
                <input name="combine" id="combine" className="form-check-input" type="radio" onChange={selectWTE} value="combiner"/>
                <label htmlFor="combine" className="form-check-label">Combiner box</label>
              </div>
            </div>
          )}
          {stringsMaxCount > 0 && (<p>Strings ({stringMinLength} - {stringMaxLength} panels): </p>)}
          {stringsMaxCount > 0 && (<SolarPanelsStrings eq_id={props.eq_id} stringMinLength={stringMinLength} stringMaxLength={stringMaxLength} stringsMaxCount={stringsMaxCount}/>)}
        </form>
      </div>
    </div>
  )
}

function EquipmentParametersContainer(props) {
  return (
    <div className="row mt-2">
      <div className="col">
        <EquipmentParameters eq_id={props.eq_id} />
      </div>
    </div>
  )
}

function ParametersPanel() {
  const equipmentBlocks = useSelector(s => s.equipment_blocks)
  return (
    <div>
      <div className="row">
        <div className="col">
          <EnvironmentParameters />
        </div>
      </div>
      {equipmentBlocks.map((i) => (<EquipmentParametersContainer eq_id={i} key={i} />))}
    </div>
  )
}

function InverterDataSheet(props) {
  const [inverterDetails, setInverterDetails] = useState({})
  const equipmentSelector = (state, eq) => {
    if (state.equipment[props.eq_id] === undefined || state.equipment[props.eq_id][eq] === undefined) {
      return
    }
    return state.equipment[props.eq_id][eq]
  }
  const inverter = useSelector(state => equipmentSelector(state, 'inverter'))
  const panel = useSelector(state => equipmentSelector(state, 'solar_panel'))
  const optimizer = useSelector(state => equipmentSelector(state, 'optimizer'))
  const environment = useSelector(state => state.environment)

  useEffect(() => {

    if (inverter === undefined || panel === undefined) {
      return false
    }
    if (inverter.type === 2 && optimizer === undefined) {
      return false
    }
    let url = API_ENDPOINT + '/inverters/' + inverter.id + '/panel/' + panel.id +
      '?t_min=' + environment.min + '&t_max='  + environment.max + '&mount=' + environment.mount
    if (optimizer) {
      url += '&optimizer_id=' + optimizer.title
    }

    fetch(url).then(res => {
      res.json().then(data => {
        setInverterDetails(data)
      })
    })
  }, [environment.max, environment.min, environment.mount, inverter, optimizer, panel])
  return (
    <div>
      {inverterDetails.title && (
        <div className="card">
          <div className="card-header">
            Inverter {props.eq_id + 1}
          </div>
          <div className="card-body">
            <table className="table">
              <tbody>
              <tr>
                <td>Title</td>
                <td>{inverterDetails.title}</td>
              </tr>
              <tr>
                <td>OCPD rating</td>
                <td>{inverterDetails.max_ocpd_rating}</td>
              </tr>
              <tr>
                <td>Rated AC voltage</td>
                <td>{inverterDetails.rated_ac_voltage}</td>
              </tr>
              <tr>
                <td>Rated AC power</td>
                <td>{inverterDetails.rated_ac_power}</td>
              </tr>
              <tr>
                <td>Max input power</td>
                <td>{inverterDetails.max_input_power}</td>
              </tr>
              <tr>
                <td>Max input current</td>
                <td>{inverterDetails.max_input_current}</td>
              </tr>
              <tr>
                <td>Max input voltage</td>
                <td>{inverterDetails.max_input_voltage}</td>
              </tr>
              <tr>
                <td>Nominal input voltage</td>
                <td>{inverterDetails.nominal_input_voltage}</td>
              </tr>
              <tr>
                <td>Nominal output power</td>
                <td>{inverterDetails.max_output_power}</td>
              </tr>
              <tr>
                <td>Nominal output current</td>
                <td>{inverterDetails.max_output_current}</td>
              </tr>
              <tr>
                <td>Minimal operating voltage</td>
                <td>{inverterDetails.min_operating_voltage}</td>
              </tr>
              <tr>
                <td>Max strings count</td>
                <td>{inverterDetails.max_string_count}</td>
              </tr>
              <tr>
                <td>Min string length</td>
                <td>{inverterDetails.min_string_length}</td>
              </tr>
              <tr>
                <td>Max string length</td>
                <td>{inverterDetails.max_string_length}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        )
      }
    </div>
  )
}

function SolarPanelDatasheet(props) {
  const [panelDetails, setPanelDetails] = useState({})
  const equipmentSelector = (state, eq) => {
    if (state.equipment[props.eq_id] === undefined || state.equipment[props.eq_id][eq] === undefined) {
      return
    }
    return state.equipment[props.eq_id][eq]
  }
  const panel = useSelector(state => equipmentSelector(state, 'solar_panel'))
  const environment = useSelector(state => state.environment)

  useEffect(() => {
    if (panel === undefined) {
      return false
    }
    const url = API_ENDPOINT + '/panels/' + panel.id + '?t_min=' + environment.min + '&t_max=' + environment.max + '&mount=' + environment.mount
    fetch(url).then(res => {
      res.json().then(data => {
        setPanelDetails(data)
      })
    })
  }, [environment.max, environment.min, environment.mount, panel])
  return (
    <div>
      {panelDetails.title && (
        <div className="card">
          <div className="card-header">
            Solar panel {props.eq_id + 1}
          </div>
          <div className="card-body">
            <table className="table">
              <tbody>
              <tr>
                <td>Title</td>
                <td>{panelDetails.title}</td>
              </tr>
              <tr>
                <td>Current mp</td>
                <td>{panelDetails.current_mp}</td>
              </tr>
              <tr>
                <td>Current sc</td>
                <td>{panelDetails.current_sc}</td>
              </tr>
              <tr>
                <td>Current sc temp cf</td>
                <td>{panelDetails.current_sc_temp_cf}</td>
              </tr>
              <tr>
                <td>Fuse nominal</td>
                <td>{panelDetails.fuse_nominal}</td>
              </tr>
              <tr>
                <td>Voltage mp</td>
                <td>{panelDetails.voltage_mp}</td>
              </tr>
              <tr>
                <td>Voltage oc</td>
                <td>{panelDetails.voltage_oc}</td>
              </tr>
              <tr>
                <td>Voltage oc temp cf</td>
                <td>{panelDetails.voltage_oc_temp_cf}</td>
              </tr>
              <tr>
                <td>PTC power</td>
                <td>{panelDetails.ptc_power}</td>
              </tr>
              <tr>
                <td>Max power</td>
                <td>{panelDetails.max_power}</td>
              </tr>
              <tr>
                <td>Max power temp cf</td>
                <td>{panelDetails.max_power_temp_cf}</td>
              </tr>
              <tr>
                <td>Max output voltage oc</td>
                <td>{panelDetails.max_output_voltage_oc}</td>
              </tr>
              <tr>
                <td>Min output voltage mp</td>
                <td>{panelDetails.min_output_voltage_mp}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

function DCOptimizersDatasheet(props) {
  const [optDetails, setOptDetails] = useState(undefined)
  const equipmentSelector = (state, eq) => {
    if (state.equipment[props.eq_id] === undefined || state.equipment[props.eq_id][eq] === undefined) {
      return
    }
    return state.equipment[props.eq_id][eq]
  }
  const optimizer = useSelector(state => equipmentSelector(state, 'optimizer'))

  useEffect(() => {
    if (optimizer === undefined) {
      setOptDetails(undefined)
      return false
    }
    const url = API_ENDPOINT + '/dc_optimizers/' + optimizer.title
    fetch(url).then(res => {
      res.json().then(data => {
        setOptDetails(data)
        console.log(data)
      })
    })
  }, [optimizer])

  return (
    <div>
      {optDetails && (
        <div className="card">
          <div className="card-header">
            DC Optimizer {props.eq_id + 1}
          </div>
          <div className="card-body">
            <table className="table">
              <tbody>
              <tr>
                <td>Title</td>
                <td>{optDetails.title}</td>
              </tr>
              <tr>
                <td>Max input current</td>
                <td>{optDetails.max_input_current}</td>
              </tr>
              <tr>
                <td>Max output current</td>
                <td>{optDetails.max_output_current}</td>
              </tr>
              <tr>
                <td>Max voltage</td>
                <td>{optDetails.max_voltage}</td>
              </tr>
              <tr>
                <td>Min string length</td>
                <td>{optDetails.min_string_length}</td>
              </tr>
              <tr>
                <td>Rated input power</td>
                <td>{optDetails.rated_input_power}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

function WiresTable() {
  const wiring = useSelector(state => state.circuit.journal)
  if (wiring === undefined) {
    return false
  }
  return (
    <div className="card">
          <div className="card-header">
            Wiring
          </div>
          <div className="card-body">
            <table className="table">
              <thead>
              <tr>
                <th>Segment #</th>
                <th>Equipment</th>
                <th>Length</th>
                <th>Conduit type</th>
                <th>Conduit size</th>
                <th>OCPD rating</th>
                <th>Cont. current</th>
                <th>Max cont. current</th>
                <th>TCF 90°</th>
                <th>Wires qty</th>
                <th>CFF</th>
                <th>Ampacity 75°</th>
                <th>Ampacity 90°</th>
                <th>Derated amp. 90°</th>
                <th>Voltage drop</th>
                <th>Voltage drop %</th>
              </tr>
              </thead>
              {wiring.map((segment, i) => (
                <tbody key={i}>
                  <tr>
                    <td># {i + 1}</td>
                    <td>{segment.start} - {segment.end}</td>
                    <td>{segment.length}</td>
                    <td>{segment.conduit.type}</td>
                    <td>{segment.conduit.size}</td>
                    <td>{segment.ocpd}</td>
                    <td>{segment.calculus.current}</td>
                    <td>{segment.calculus.max_current}</td>
                    <td>{segment.calculus.tfc_90}</td>
                    <td>{segment.calculus.wires_qty}</td>
                    <td>{segment.calculus.cff}</td>
                    <td>{segment.calculus.ampacity_75}</td>
                    <td>{segment.calculus.ampacity_90}</td>
                    <td>{segment.calculus.derated_90}</td>
                    <td>{segment.calculus.voltage_drop.toFixed(4)}</td>
                    <td>{segment.calculus.voltage_drop_perc.toFixed(4)}</td>
                  </tr>
                  <tr>
                    <td colSpan="16">
                      <table className="table">
                        <thead>
                        <tr>
                          <th>Wire</th>
                          <th>Material</th>
                          <th>Type</th>
                          <th>Gauge</th>
                        </tr>
                        </thead>
                        <tbody>
                          {segment.wires.map((wire, j) => (
                            <tr key={j}>
                              <td style={{width: '10%'}}>{wire.name}</td>
                              <td>{wire.material}</td>
                              <td>{wire.type}</td>
                              <td>{wire.gauge} AWG</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
    </div>
  )
}

function ResultsPanel() {
  const equipment_blocks = useSelector(state => {
    return state.equipment_blocks
  })
  const equipment = useSelector(state => state.equipment)
  const environment = useSelector(state => state.environment)
  const [graph, setGraph] = useState({})
  const dispatch = useDispatch()
  useEffect(() => {
    const equipmentIsReady = (eq) => {
      return eq.inverter !== undefined && eq.solar_panel !== undefined && eq.strings !== undefined
    }
    const readyEquipment = Object.values(equipment).filter(eq => {
      return equipmentIsReady(eq)
    })
    if (!readyEquipment.length) {
      return false
    }
    const url = API_ENDPOINT + '/circuit?t_min=' + environment.min + '&t_max=' + environment.max + '&mount=' + environment.mount + '&segment_len='+ environment.segment_len
    const payload = readyEquipment.map(eq => {
      let p = {
        'panel': eq.solar_panel.title,
        'inverter': eq.inverter.id,
        'strings': Object.values(eq.strings),
        'wte': eq.wte
      }
      if (eq.optimizer !== undefined) {
        p.optimizer = eq.optimizer.title
      }
      return p
    }, readyEquipment)

    fetch(url, {method: 'POST', body: JSON.stringify(payload), headers: {'Content-Type': 'application/json'}}).then(res => {
      res.json().then(data => {
        setGraph({})
        setGraph(data.circuit)
        dispatch({
          type: SET_CIRCUIT,
          payload: {
            circuit: data
          }
        })
      })
    })

  }, [dispatch, environment.max, environment.min, environment.mount, environment.segment_len, equipment])
  return (
    <div>
      <div className="row">
        {equipment_blocks.map(block => (<div className="col" key={block}><InverterDataSheet eq_id={block} /></div>))}
      </div>
      <div className="row mt-3">
        {equipment_blocks.map(block => (<div className="col" key={block}><SolarPanelDatasheet eq_id={block} /></div>))}
      </div>
      <div className="row mt-3">
        {equipment_blocks.map(block => (<div className="col" key={block}><DCOptimizersDatasheet eq_id={block} /></div>))}
      </div>
      <div className="row mt-3">
        {graph.nodes && (<Graph graph={graph} options={{physics: {enabled: false}, height: 800, layout: {hierarchical: {enabled: true, direction: 'RL'}}}}/>)}
      </div>
      <div className="row mt-3">
        {graph.nodes && (<div className="col" key="wires"><WiresTable /></div>)}
      </div>
    </div>
  )
}

function App() {
  return (
    <Provider store={store}>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-2 position-sticky">
            <ParametersPanel />
          </div>
          <div className="col">
            <ResultsPanel />
          </div>
        </div>
      </div>
    </Provider>
  );
}

export default App;
